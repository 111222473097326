<template>
  <v-app>
    <TextBox
      :cefrLevel="payload.cefr_level"
      v-model="title"
      label="Title"
    />

    <label class="item-label mt-6">Speaking Text</label>
    <Textarea
      class="textarea"
      :rows="4"
      :cefrLevel="payload.cefr_level"
      v-model="speakingText"
    />

    <label class="item-label">Assessment Video</label>
    <div v-if="videoFileLocation">
      <video controls width="710">
        <source :src="videoFileLocation" type="video/mp4">
      </video>
    </div>
    <div class="d-flex justify-space-between align-center">
      <label class="es-btn small">
        Upload a new video file
        <input
          class="d-none"
          type="file"
          accept=".mp4, video/mp4"
          @change="upload($event)"
        />
        <validation-provider rules="required">
          <input type="text" v-model="videoFileLocation" class="d-none" />
        </validation-provider>
      </label>
      <v-chip
        outlined
        v-if="videoFileLocation"
        :title="videoFileLocation"
        :href="videoFileLocation"
        target="_blank">
        📽️ Open video in new tab
      </v-chip>
    </div>
  </v-app>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import TextBox from "@/components/item/TextBox";
import Textarea from "@/components/item/Textarea";
import { v4 as uuidv4 } from "uuid";
export default {
  data: () => ({
    static_text: "Repeat the phrase",
    title: "",
    rubric: "",
    speakingText: "",
    videoFilename: "",
    videoFileLocation: "",
  }),
  computed: {
    ...mapState("item", ["payload", "isUpdate"]),
    ...mapGetters("account", ["user"])
  },
  methods: {
    ...mapActions("item", ["uploadFile"]),
    async upload(event) {
      const originalVideoFileLocation = this.videoFileLocation;
      this.videoFileLocation = false;
      const formData = new FormData();
      formData.append("author_id", this.user.user_id);
      formData.append("file", event.target.files[0]);
      formData.append("content_type", "video/mp4");
      try {
        const response = await this.uploadFile(formData);
        const { file_name, file_location } = response.data;
        this.videoFilename = file_name;
        this.videoFileLocation = file_location;
      } catch (error) {
        console.error(error);
        this.videoFileLocation = originalVideoFileLocation;
      }
    },
    onSubmit() {
      const data = {
        template_type: "LISTEN_AND_REPEAT",
        static_text: this.static_text,
        title: this.title,
        rubric: this.speakingText, // @note: rubric is intentionally a copy of speakingText, because backend reasons
        speaking_text: this.speakingText,
        assets: {
          videos: [{
            file_name: this.videoFilename,
            file_location: this.videoFileLocation,
          }]
        }
      }

      /**
       * The questions[] array here is hard-coded because
       *  a) it is designed to be static;
       *  b) the API requires it.
       *
       * Note: It's set on create only, never for updates.
       */
      if (!this.isUpdate) {
        data.questions = [{
          question: this.static_text, // @note: question is intentionally a copy of static_text, because backend reasons
          question_id: uuidv4(),
          solution: [],
          metadatas: {"instructions": ["Hold and repeat the sentence"]}
        }]
      }
      this.$store.commit('item/setPayload', data)
    }
  },
  beforeMount() {
    if (this.isUpdate) {
      const { title, speaking_text: speakingText } = this.payload
      this.title = title;
      this.speakingText = speakingText;

      const { file_name: videoFilename, file_location: videoFileLocation } = this.payload.assets.videos[0];
      this.videoFileLocation = videoFileLocation;
      this.videoFilename = videoFilename;
    }
  },
  components: {
    TextBox,
    Textarea,
  },
};
</script>

<style lang="scss" scoped>
audio {
  width: 100%;
}
.title {
  font-size: 1.5em;
  font-weight: bold;
  padding: 10pt;
  width: 80%;
  border: unset;
  border-bottom: 3pt solid rgb(222, 222, 222);
}
.paragraphs {
  max-width: 500pt;
  box-sizing: border-box;
  .paragraph {
    display: flex;
    margin-bottom: 15pt;
    .number {
      position: relative;
      background: #393b71;
      display: block;
      height: 21pt;
      width: 21pt;
      border-radius: 21pt;
      text-align: center;
      color: white;
      margin-top: 13pt;
      margin-right: 10pt;
    }
    .textarea {
      width: 100%;
    }
  }
}
label {
  color: #264d98;
  font-size: 1em;
  margin-bottom: 10pt 0pt;
}
::v-deep {
  .v-application--wrap {
    min-height: auto;
  }
}
</style>
